<template>
  <form
    class="filter-bar sticky-top"
    :class="[
      { 'filter-bar--visible': showFilters },
      { 'mb-3': showFilters },
    ]"
  >
    <div class="d-flex p-2 bg-body rounded shadow">
      <div class="d-flex flex-wrap gap-2">
        <checkbox-filter
          v-model="filters.isActivated"
          name="Actief"
          :options="new Map([
            [true, 'Ja'],
            [false, 'Nee'],
          ])"
        />

        <checkbox-filter
          v-model="filters.hasRoles"
          name="Rollen"
          :options="filterOptions.roles"
        />

        <!-- <checkbox-filter
          v-model="filters.hasBoardFunction"
          name="Bestuursfunctie"
          :disabled="disableBoardFunctionsDropdown"
          :options="filterOptions.boardFunctions"
        /> -->
      </div>

      <div class="col-3 ms-auto">
        <material-input
          id="app-users-search"
          v-model="searchQuery"
          placeholder="Zoeken..."
          type="search"
        />
      </div>
    </div>
  </form>

  <div
    v-for="user in filteredItems.slice(0, displayedUserCount)"
    :key="user.id"
    class="mt-3 mb-3 col-lg-4 col-md-6"
  >
    <user-card
      :id="user.id"
      :board-member-function="user.boardMemberFunction"
      :image="user.profilePicture"
      :is-activated="user.isActivated"
      :name="user.fullName"
      :profession="user.profession"
      :company-name="user.companyName"
      :is-admin="user.isAdmin"
      @remove="removeItem(user.id)"
    />
  </div>

  <list-cutoff
    v-if="filteredItems.length > displayedUserCount"
    show-more-button-text="Laad volgende 60 gebruikers"
    show-all-button-text="Laad alle gebruikers"
    @show-more-button-pressed="showNext60Items"
    @show-all-button-pressed="showAllItems"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { getAll, sendInactiveInvitations } from '@/api/providers/users';
import { getAll as getAllRoles } from '@/api/providers/roles';

import ListCutoff from '@/components/UI/ListCutoff.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import UserCard from '@/components/AppUsers/UserCard.vue';
import CheckboxFilter from '@/components/UI/CheckboxFilter.vue';

import initializeSearchBarFocusEvent from '../../helpers/search-bar';

export default {
  name: 'AppUsers',

  components: {
    CheckboxFilter,
    ListCutoff,
    MaterialInput,
    UserCard,
  },

  data: () => ({
    filterOptions: {
      roles: [],
      boardFunctions: []
    },
    filters: {
      isActivated: [],
      hasRoles: [],
      hasBoardFunction: [],
    },
    items: [],
    searchQuery: '',
    showFilters: false,
    displayedUserCount: 60
  }),

  computed: {
    filteredItems() {
      return this.items
        .filter(this.searchFilter)
        .filter(this.userIsActivatedFilter)
        .filter(this.roleFilter)
        .filter(this.boardFunctionFilter);
    },

    lowerCaseSearchTerms() {
      return this.searchQuery.toLowerCase().split(' ');
    },
    
    disableBoardFunctionsDropdown() {
      return this.filterOptions.boardFunctions.length < 1;
    }
  },

  async mounted() {
    initializeSearchBarFocusEvent(
      () => this.showFilters = true
    );

    this.loadUsers();
    this.addActions();

    getAllRoles()
      .then(roles => this.filterOptions.roles = roles.map(r => [r.id, r.name]))
  },

  methods: {
    ...mapActions('appHeader', [
      'addAction',
    ]),

    ...mapActions('notification', {
      addNotification: 'add',
    }),

    addActions() {
      this.addAction({
        handler: () => this.showFilters = !this.showFilters,
        icon: 'tune',
        text: 'Filters',
      });

      this.addAction({
        handler: this.inviteAll,
        icon: 'forward_to_inbox',
        text: 'Verstuur app-uitnodigingen',
      });

      this.addAction({
        handler: () => this.$router.push({
          name: 'new-app-user',
        }),
        icon: 'person_add',
        text: 'Nieuw',
      });
    },

    filtersFilter(item) {
      return Object.entries(this.filters)
        .every(([property, values]) => (
          values.includes(item[property])
          || !values.length
        ));
    },

    roleFilter(item) {
      if (this.filters.hasRoles.length === 0) return true;

      return this.filters.hasRoles.includes(item.roleId);
    },

    userIsActivatedFilter(item) {
      if (this.filters.isActivated.length === 0) return true;

      return this.filters.isActivated.includes(item.isActivated);
    },

    inviteAll() {
      const inactiveAppUsersAmount = (
        this.items
          .filter(({ isActivated }) => !isActivated)
          .length
      );

      if (!inactiveAppUsersAmount) {
        return alert('Er zijn momenteel geen app-gebruikers die geen gebruik maken van de app.');
      }

      if (!confirm(`Wil je ${inactiveAppUsersAmount} inactieve app-gebruiker${inactiveAppUsersAmount > 1 ? 's' : ''} uitnodigen?`)) {
        return;
      }

      sendInactiveInvitations()
        .then(() => {
          this.addNotification({
            description: `Er ${inactiveAppUsersAmount > 1 ? 'zijn' : 'is'} ${inactiveAppUsersAmount} app-uitnodiging${inactiveAppUsersAmount > 1 ? 'en' : ''} verzonden.`,
            icon: {
              component: 'mark_email_read',
            },
          });
        });
    },

    loadUsers() {
      getAll()
        .then((data) => {
          this.items = data;
          this.filterOptions.boardFunctions = this.buildBoardFunctionDropdownContent(data);
        })
        .catch((error) => error.default());
    },

    buildBoardFunctionDropdownContent(users) {
      const functions = Array.from(new Set(users
        .map(u => u.boardMemberFunction)
        .filter(f =>
          typeof(f) == 'string' &&
          f.length > 0
        )))
        .sort();

        return [
          [ null, 'Geen functie' ],
          ...functions.map(f => [f, f]),
        ]
    },

    removeItem(id) {
      this.items.splice(this.items.findIndex((item) => item.id === id), 1);
    },

    searchFilter(item) {
      return !this.searchQuery.length
        ? true
        : Object.values(item)
            .filter((value) => {
              if (typeof value !== 'string') {
                return false;
              }

              const lowerCaseValue = value.toLowerCase();

              return this.lowerCaseSearchTerms
                .filter(
                  (lowerCaseSearchTerm) => lowerCaseValue.includes(lowerCaseSearchTerm)
                ).length;
            }).length
    },

    boardFunctionFilter(item) {
      if (this.filters.hasBoardFunction.length === 0) return true;
      return this.filters.hasBoardFunction.includes(item.boardMemberFunction);
    },

    showNext60Items() {
      this.displayedUserCount += 60;
    },

    showAllItems() {
      this.displayedUserCount = Infinity;
    }
  },
};
</script>

<style scoped lang="scss">
.container-fluid {
  min-height: 100vh;
}

.filter-bar {
  height: 0;
  transition-property: height margin opacity;
  transition-duration: .2s;

  &:not(&--visible) {
    overflow: hidden;
    opacity: 0;
  }

  &--visible {
    height: 56.19px;
  }
}
</style>